import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import commingsoon2 from "./images/commingsoon2.png";
import diamondsingle from "./images/diamond-single.png";
import "./Style.css";
import { Carousel } from "react-responsive-carousel";
import pfeatu from "./images/pfeatu.png";
import parameter from "./images/daimond-parameter.png";

function Membrane() {
  return (
    <div className="body">
      <div className="container">
        <div className="">
          <h1
            style={{ fontWeight: "bold", color: "#2a8dfc", marginTop: "60px" }}
            className="text-center "
          >
            DIAMOND VH-100-GPD PUMP
          </h1>
        </div>
        <div className="row  ">
          <div className="col-lg-6 container">
            <img
              className="  container paliwal "
              src={diamondsingle}
              alt=""
              style={{ marginTop: "15%" }}
            />
          </div>

          <div className="col-lg-4 container my-5">
            <div className="  et-pb-text-inner container">
              <Carousel className="main-slide">
                <div>
                  <img src={parameter} alt="" />
                </div>
                <div>
                  <img src={pfeatu} alt="" />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Membrane;
