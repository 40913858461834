import React from "react";
import op from "./images/op.jpg";
import membrane from "./images/membrane.jpg";
import tape from "./images/tape.jpg";
import pump from "./images/pump.jpg";
import "./Style.css";
export default function Ourproducts() {
  return (
    <div className="body">
      <img
        src={op}
        className="img-fluid"
        alt="Responsive imag"
        style={{ width: "100%" }}
      />

      <div
        style={{
          backgroundColor: "white",
        }}
      >
        <div className="container my-5">
          <div className="row text-center">
            <div className="col-lg-4">
              <img src={pump} alt="" height={220} />
              <h3>BOOSTER PUMP</h3>
              <a href="Pump" className="btn btn-outline-dark hoverme">
                VIEW PRODUCTS
              </a>
            </div>
            <div className="col-lg-4">
              <img src={membrane} alt="" height={220} />
              <h3>RESIDENTIAL RO MEMBRANE</h3>
              <a href="Membrane" className="btn btn-outline-dark hoverme">
                VIEW PRODUCTS
              </a>
            </div>
            <div className="col-lg-4">
              <img src={tape} alt="" height={220} />
              <h3>P.T.F.E. THREAD SEAL TAPE</h3>
              <a href="Tape" className="btn btn-outline-dark hoverme">
                VIEW PRODUCTS
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
