import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import GPD100 from "./images/GPD100.jpg";
import GPD150 from "./images/GPD150.jpg";
import GPD300 from "./images/GPD300.jpg";
import ppera from "./images/ppera.png";
import p150 from "./images/p150.png";
import p300 from "./images/p300.png";
import pfeatu from "./images/pfeatu.png";
import "./Style.css";

function Pump() {
  return (
    <div className="body">
      <div className="container">
        <div className="">
          <h1
            style={{ fontWeight: "bold", color: "#2a8dfc", marginTop: "60px" }}
            className="text-center "
          >
            LEGENDER VH-100-GPD PUMP
          </h1>
        </div>
        <div className="row  ">
          <div className="col-lg-6 container">
            <img
              className="  container paliwal "
              src={GPD100}
              alt=""
              style={{ marginTop: "15%" }}
            />
          </div>

          <div className="col-lg-4 container my-5">
            <div className="  et-pb-text-inner container">
              <Carousel className="main-slide">
                <div>
                  <img src={ppera} alt="" />
                </div>
                <div>
                  <img src={pfeatu} alt="" />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="">
          <h1
            style={{ fontWeight: "bold", color: "#2a8dfc" }}
            className="text-center "
          >
            LEGENDER VH-150-GPD PUMP
          </h1>
        </div>
        <div className="row   ">
          <div className="col-lg-6 container ">
            <img
              className=" container  paliwal "
              src={GPD150}
              alt=""
              style={{ marginTop: "15%" }}
            />
          </div>

          <div className="col-lg-4 container my-5">
            <div className="  et-pb-text-inner container">
              <Carousel>
                <div>
                  <img src={p150} alt="" />
                </div>
                <div>
                  <img src={pfeatu} alt="" />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="">
          <h1
            style={{ fontWeight: "bold", color: "#2a8dfc" }}
            className="text-center  "
          >
            LEGENDER VH-300-GPD PUMP
          </h1>
        </div>
        <div className="row  ">
          <div className="col-lg-6 container ">
            <img
              className=" container   paliwal "
              src={GPD300}
              alt=""
              style={{ marginTop: "15%" }}
            />
          </div>

          <div className="col-lg-4 container my-5">
            <div className="  et-pb-text-inner container">
              <Carousel>
                <div>
                  <img src={p300} alt="" />
                </div>
                <div>
                  <img src={pfeatu} alt="" />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Pump;
