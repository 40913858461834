import React from "react";
// import contactus from "./images/contactus.jpg";
import mail from "./images/mail.png";
import contact from "./images/contact.png";
import location from "./images/location.png";
import contactus1 from "./images/contactus1.jpg";
import "./Style.css";
import { useState } from "react";

export default function Contactus() {
  const [full_name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const postPutEvent = async(event) => {
    event.preventDefault();
    if(!full_name||!email||!phone||!message){
      alert("Please enter All Fields")
      return
    }
    const data = {
      full_name,
      email,
      phone,
      message,
    };
    const url = "https://api.vhro.in/api/product/Contact";
    console.log(data);
    try {
      let res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      res = await res.json();
      alert("mail sent");
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="body">
      <img
        src={contactus1}
        className="img-fluid"
        alt="error img"
        style={{ width: "100%" }}
      />

      <div className=" container my-3 ">
        <div className="row text-center ">
          <div
            className="col-sm-6 col-xs-6 first-box my-3 mx-0  "
            style={{
              padding: "10px",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <h1>
              <span className="glyphicon glyphicon-earphone"></span>
            </h1>
            <img src={mail} alt="" style={{ height: "50px", margin: "20px" }} />
            <p style={{ fontWeight: "bold" }}>EMAIL</p>
            <a
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
              }}
              href="mailto:info@vhro.in"
              target="_blan"
              rel="nopper"
            >
              info@vhro.in{" "}
            </a>
          </div>
          <div
            className="col-sm-6 col-xs-6 second-box my-3 mx-0  "
            style={{
              padding: "10px",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <h1>
              <span className="glyphicon glyphicon-home"></span>
            </h1>
            <img
              src={contact}
              alt=""
              style={{ height: "50px", margin: "20px" }}
            />
            <p style={{ fontWeight: "bold" }}>PHONE</p>
            <a
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
              }}
              href="tel:918141318161,tel:9141310400,tel:917878486848"
            >
              (+91) 81413 18161 <br />
              (+91) 81413 10400 <br /> (+91) 78784 86848{" "}
            </a>
          </div>
          <div
            className="col-sm-4 col-xs-6 third-box my-3  "
            style={{
              padding: "10px",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <h1>
              <span className="glyphicon glyphicon-send"></span>
            </h1>
            <img
              src={location}
              alt=""
              style={{ height: "50px", margin: "20px", fontWeight: "bold" }}
            />
            <p style={{ fontWeight: "bold" }}>CORPORATE OFFICE</p>
            <p>
              <a
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold",
                }}
                href="https://goo.gl/maps/Hc56F3q4tHJbSiuw7"
                target="_blan"
                rel="nopper"
              >
                Devla gate, Vajalpara,
                <br />
                Savarkundla – Gujarat 364515, INDIA
                <br />
                (+91) 81413 18161
              </a>
            </p>
          </div>
          <div
            className="col-sm-4 col-xs-6 fourth-box my-3  "
            style={{
              padding: "10px",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <h1>
              <span className="glyphicon glyphicon-leaf"></span>
            </h1>
            <img
              src={location}
              alt=""
              style={{ height: "50px", margin: "20px", fontWeight: "bold" }}
            />
            <p style={{ fontWeight: "bold" }}>AHMEDABAD OFFICE</p>
            <p>
              <a
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold",
                }}
                href="https://goo.gl/maps/KGyyxUDJZ5CGCvX46"
                target="_blan"
                rel="nopper"
              >
                Shed-30, Matru Estate,
                <br />
                Odhav – Ahmedabad, Gujarat 382350, INDIA
                <br />
                (+91) 81413 10400
              </a>
            </p>
          </div>
          <div
            className="col-sm-4 col-xs-6 fourth-box my-3  "
            style={{
              padding: "10px",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              // boxShadow: "rgba(0, 0, 0, 0.20) 0px 5px 15px"
            }}
          >
            <h1>
              <span className="glyphicon glyphicon-leaf"></span>
            </h1>
            <img
              src={location}
              alt=""
              style={{ height: "50px", margin: "20px", fontWeight: "bold" }}
            />
            <p style={{ fontWeight: "bold" }}>FACTORY ADDRESS</p>
            <p>
              <a
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold",
                }}
                href="https://goo.gl/maps/Hc56F3q4tHJbSiuw7"
                target="_blan"
                rel="nopper"
              >
                Devla gate, Opp. Police Chowki,
                <br />
                Savarkundla – Gujarat 364515, INDIA
                <br />
                (+91) 78784 86848
              </a>
            </p>
          </div>
        </div>
      </div>

      <section id="contact">
        <div className="container">
          {/* <div className="well well-sm">
            <h3 className="hover">
              <strong>Contact Us</strong>
            </h3>
          </div> */}

          <div className="row">
            <div className="col-md-7">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7432.524480870462!2d71.304403!3d21.340149!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2ba40adc85bfee26!2sVH%20RO!5e0!3m2!1sen!2sin!4v1676526377643!5m2!1sen!2sin"
                width="100%"
                height="315"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="col-md-5">
              <h4 className="hover">
                <strong>Get in Touch</strong>
              </h4>
              <form>
                <div className="mb-3">
                  <input
                    onChange={(e) => setName(e.target.value)}
                    value={full_name}
                    type="Name"
                    placeholder="Full name*"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  <div id="emailHelp" className="form-text"></div>
                </div>
                <div className="mb-3">
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="Name"
                    placeholder="Email*"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  <div id="emailHelp" className="form-text"></div>
                </div>

                <div className="mb-3">
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    type="tel"
                    placeholder="Phone*"
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    type="text area"
                    placeholder="Message*"
                    className="form-control"
                    rows="3"
                    id="exampleInputPassword1"
                  />
                </div>
                <button
                  onClick={postPutEvent}
                  name="submit"
                  type="submit"
                  id="contact-submit"
                  data-submit="...Sending"
                  className="btn btn-outline-dark hoverme"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
