import React from "react";
import Slider from "react-slick";

const Testimonial = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    className: "center",
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay:true,
    autoplaySpeed: 2000,
    
    centerPadding: "0px",
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <br/>

      <h2 style={{textAlign:"center",fontWeight:"bolder",color:"#2a8dfc"}} >Our Happy Customers</h2>
      <br />
      <div className=" container slider-container">
        <Slider {...settings}>
            <div>

          <div  style={{margin:"20px"}} >
            <h4 style={{fontWeight:"bolder",textAlign:"center"}}>Vikash Choudhry</h4>
            <h6 style={{fontWeight:"bold",textAlign:"center"}}>Sunexa Filtration Praivat Limitet</h6>
            <p style={{textAlign:"justify"}}>
              As a distributor, our reputation depends on the quality of the
              products we offer. That's why we trust V.H. RO for our booster
              pump needs. Their pumps are engineered with precision and built to
              withstand even the toughest conditions. Our customers appreciate
              the reliability and efficiency of the pumps, and we appreciate the
              reliability and professionalism of V.H. RO, A winning
              combination!"
            </p>
          </div>
            </div>
          <div>

          <div style={{margin:"20px"}}>
            <h4 style={{fontWeight:"bolder",textAlign:"center"}}>Anand Jain</h4>
            <h6 style={{fontWeight:"bold",textAlign:"center"}}>Shree Nakoda Sales</h6>
            <p style={{textAlign:"justify"}}>
              Choosing to distribute V.H RO's booster pumps was one of the best
              decisions we've made for our business. Not only do their products
              deliver exceptional performance, but their team's dedication to
              customer satisfaction sets them apart. From timely deliveries to
              expert technical support, they truly understand the importance of
              supporting their distributors every step of the way.
            </p>
          </div>
          </div>
          <div>

          <div style={{margin:"20px"}}>
            <h4 style={{fontWeight:"bolder",textAlign:"center"}}>Vivek Sager</h4>
            <h6 style={{fontWeight:"bold",textAlign:"center"}}>New Prasad Aqua RO System</h6>
            <p style={{textAlign:"justify"}}>
              Working with V.H RO as a distributor for their booster pumps has
              been an absolute pleasure. Their products are best in terms of
              quality and reliability. Our customers have consistently
              appreciate the performance of the pumps, and their prompt
              technical support ensures any issues are easily resolved. Highly
              recommended Brand.
            </p>
          </div>
          </div>
          <div>

          <div style={{margin:"20px"}}>
            <h4 style={{fontWeight:"bolder",textAlign:"center"}}>T. Santhosh</h4>
            <h6 style={{fontWeight:"bold",textAlign:"center"}}>Bhageeratha Water Solution</h6>
            <p style={{textAlign:"justify"}}>
              We've been partnering with V.H. RO for several years now, and
              their booster pumps have become a cornerstone of our product
              lineup. The pumps' efficiency and durability have earned rave
              reviews from our customers, helping us solidify our reputation as
              a trusted supplier in the industry. Thank you, V.H. RO, for
              consistently delivering excellence.
            </p>
          </div>
          </div>
          <div>

          <div style={{margin:"20px"}}>
            <h4 style={{fontWeight:"bolder",textAlign:"center"}}>Himanshu Mehta</h4>
            <h6 style={{fontWeight:"bold",textAlign:"center"}}>Safeway RO System</h6>
            <p style={{textAlign:"justify"}}>
              In today's fast-paced times, there is no commitment value or any
              kind of transference, there VH RO has created a different image by
              giving all its customers commitment and transference. VH RO
              provides a separate area to work and good profit. Because of this
              transparent dealer policy and the honesty of VH RO, many customers
              like us have been associated with VH RO for a long time.
            </p>
          </div>
          </div>
        </Slider>
      </div>
      <br />
    </div>
  );
};

export default Testimonial;
