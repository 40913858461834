import React from "react";
import aimage1 from "./images/aimage1.jpg";
import hand from "./images/hand.png";
import mission from "./images/mission.png";
import vision from "./images/vision.png";
import values from "./images/values.png";
import innovation from "./images/innovation.png";
import production from "./images/production.png";
import quality from "./images/quality.png";
import research from "./images/research.png";
import money from "./images/money.png";
import customer from "./images/customer.png";
import c1 from "./images/c1.png";
import c2 from "./images/c2.png";
import c3 from "./images/c3.png";
import c4 from "./images/c4.png";

import "./Style.css";
export default function Aboutus() {
  return (
    <div className="body">
      <div style={{ overflowX: "hidden" }}>
        <img
          src={aimage1}
          className="img-fluid"
          alt="Responsive imag"
          style={{ width: "100%" }}
        />
        <section
          className="service-details-page pdt-70 pdb-90 my-5  dtt   "
          style={{ width: "100%" }}
        >
          <h2
            className="display-4"
            style={{
              color: "#2a8dfc",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Who We Are
          </h2>
          <div className="container  ">
            <div className="row  ">
              <div className="col-xl-12 col-lg-12 ">
                <div className="service-detail-text ">
                  <div className="row d-flex" style={{ alignItems: "center" }}>
                    <div className="  col-lg-5 col-xl-5  ms-auto mb-n20  mb-xxl-n22  ">
                      <div className="box-ponks">
                        <img
                          className=" container"
                          src={hand}
                          alt="img error"
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-7 col-xl-7"
                      style={{
                        textAlign: "justify",
                        textJustify: "inter-word",
                      }}
                    >
                      <p className="p">
                        The “Hiren Water Tech Pvt. Ltd.” is one of the leading
                        Booster Pumps Manufacturer’s with most dynamic &
                        productive brands in the water industry. Since 2017 -
                        The Inception Year, so today we have expanded our reach
                        to national & international arenas. Finest skills
                        clubbed with state-of-the-art technology have helped us
                        carve a niche stamped by authorities as we are ISO
                        9001:2015 Certified.
                      </p>
                      <p className="p">
                        Hiren Water Tech Pvt. Ltd. is identified as a benchmark
                        for innovation. Accompanied with methods of production
                        that provide quality, Hiren Water Tech is also known as
                        one of the largest producers in India. In a span of
                        fewer than five years, the company has achieved a brand
                        status that effuses trust, sophisticated style, and
                        long-lasting quality.
                      </p>
                      <p className="p">
                        Our supreme quality is a result of the leading-edge
                        production process that includes high-end machinery,
                        quality control process, research, development, and
                        automation. Making quality the central attitude in all
                        facets of the company’s actions, it strives to better
                        itself over time by learning from hiccups, challenges,
                        and strategic blunders. Learning has become a norm at
                        the company premises and in the minds of its people.
                      </p>
                      <p className="p">
                        Hard work in terms of speed of execution and smart work
                        in terms of excellent product, great customer
                        relationships, and professional business management have
                        together blended to elevate the brand to be one of the
                        most preferred in the industry.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="container"
          style={{ paddingTop: "20px", paddingBottom: "0px" }}
        >
          <div className="row text-center ">
            <div className="col-lg-4 ">
              <img src={mission} alt="" height={135} />
              <h3 className="my-3">Mission</h3>
              <p
                className="mt-4 mb-4"
                style={{ textAlign: "justify", textJustify: "inter-word" }}
              >
                Collaboratively design and implement solutions, together with
                our clients, that truly makes a difference. We are committed to
                growing with our customers by delivering greater business value
                through the alignment of technology, business processes,
                organisational structure and strategy.
              </p>
            </div>
            <div className="col-lg-4">
              <img src={vision} alt="" height={135} />
              <h3 className="my-3">Vision</h3>
              <p
                className="mt-4 mb-4"
                style={{ textAlign: "justify", textJustify: "inter-word" }}
              >
                Our vision provides the foundation and structure for our mission
                and directs every portion of our business by pointing directions
                to move forward, which will ultimately give our company steady
                and ever-increasing growth. As a group, we would like to be a
                leading manufacturer & exporter in the water industry in India.
              </p>
            </div>
            <div className="col-lg-4">
              <img src={values} alt="" height={135} />
              <h3 className="my-3">Value</h3>
              <p
                className="mt-4 mb-4"
                style={{ textAlign: "justify", textJustify: "inter-word" }}
              >
                We regard quality as our most successful attribute. It is the
                key to our success, today and tomorrow. Quality is a hallmark of
                our products. We will ensure growth commitment by offering a
                quality product and service that exceeds the customer’s
                expectations at a profitable and reasonable cost.
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div
            className="content "
            style={{
              backgroundColor: " ",
            }}
          >
            <div className="container ">
              <div className="row text-center">
                <h1
                  style={{
                    paddingTop: "0px",
                    color: "#2a8dfc",
                    fontWeight: "bold",
                  }}
                  className="hover my-4 "
                >
                  Why Choose Us
                </h1>
                <div className="col-lg-4 my-2">
                  <img src={innovation} alt="" height={150} />
                  <p>Modernized Technology</p>
                </div>
                <div className="col-lg-4 my-2">
                  <img src={production} alt="" height={150} />
                  <p>High Production Capacity</p>
                </div>
                <div className="col-lg-4 my-2">
                  <img src={quality} alt="" height={150} />
                  <p>Top Quality Products</p>
                </div>
                <div className="col-lg-4 my-2">
                  <img src={research} alt="" height={150} />
                  <p>Research and Development Team (R&D)</p>
                </div>
                <div className="col-lg-4 my-2">
                  <img src={money} alt="" height={150} />
                  <p>Affordable Rates</p>
                </div>
                <div className="col-lg-4 my-2">
                  <img src={customer} alt="" height={150} />
                  <p>24/7 Customer Support</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section
          className="service-details-page pdt-70 pdb-90 py-5"
          style={{ width: "100%" }}
        >
          <div className="container  ">
            <h1
              className="text-center pb-5  "
              style={{
                paddingTop: "0px",
                color: "#2a8dfc",
                fontWeight: "bold",
              }}
            >
              {" "}
              Certificates
            </h1>
            <div className="row  ">
              <div className="col-xl-12 col-lg-12 ">
                <div className="service-detail-text ">
                  <div className="row d-flex" style={{ alignItems: "center" }}>
                    <div className="  col-lg-3 col-xl-3  ms-auto mb-n20  mb-xxl-n22  ">
                      <img className=" container  " src={c1} alt="" />
                    </div>

                    <div className="  col-lg-3 col-xl-3  ms-auto mb-n20  mb-xxl-n22">
                      <img className=" container     " src={c2} alt="" />
                    </div>
                    <div className="  col-lg-3 col-xl-3  ms-auto mb-n20  mb-xxl-n22">
                      <img className="  container    " src={c3} alt="" />
                    </div>

                    <div className="  col-lg-3 col-xl-3  ms-auto mb-n20  mb-xxl-n22">
                      <img className=" container" src={c4} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
