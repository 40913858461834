import React from "react";
import vlogo from "./images/vlogo.png";
import "./Style.css";

export default function Navbar() {
  return (
    <div className="body">
      <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light pinkblue">
        <div className="container-fluid">
          <a className="navbar-brand fw-bold" href="/Home">
            <img style={{ width: "250px" }} src={vlogo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item blue">
                <div style={{ fontWeight: "bold" }}>
                  <a
                    className="nav-link active whitebutton "
                    aria-current="page"
                    href="/Home"
                  >
                    Home
                  </a>
                </div>
              </li>
              <li className="nav-item blue">
                <div style={{ fontWeight: "bold" }}>
                  <a className="nav-link" href="/Aboutus">
                    About Us
                  </a>
                </div>
              </li>

              <li
                className="nav-item dropdown blue "
                style={{ fontWeight: "bold" }}
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Our Produts
                </a>

                <ul
                  style={{ textAlign: "left" }}
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="/Pump">
                      Legender Booster Pump
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/Membrane">
                      Diamond Booster Pump
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/Tape">
                      HAV Booster Pump
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item blue">
                <div style={{ fontWeight: "bold" }}>
                  <a className="nav-link" href="/Newsandevents">
                    Events
                  </a>
                </div>
              </li>

              <li className="nav-item blue">
                <div style={{ fontWeight: "bold" }}>
                  <a className="nav-link" href="/Gallery">
                    Gallery
                  </a>
                </div>
              </li>
              <li className="nav-item blue">
                <div style={{ fontWeight: "bold" }}>
                  <a className="nav-link" href="/ContactUs">
                    Contact Us
                  </a>
                </div>
              </li>
              <li className="nav-item blue">
                <div className="changingcolour" style={{ fontWeight: "bold" }}>
                  <a className="nav-link" href="/Ebroucher">
                    E-BROCHURE
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
