import React from "react";

import img from "./images/img.jpg";
import img2 from "./images/img2.jpg";
import img3 from "./images/img3.jpg";
import img4 from "./images/img4.jpg";
import img5 from "./images/img5.jpg";
import img6 from "./images/img6.jpg";
import img7 from "./images/img7.jpg";
import img8 from "./images/img8.jpg";
import gallery from "./images/gallery.jpg";
import "./Style.css";

export default function Gallery() {
  return (
    <div className="body">
      <img
        src={gallery}
        className="img-fluid"
        alt="Responsive imag"
        style={{ width: "100%" }}
      />

      <div className="container my-5">
        <div className="row photos">
          <div className="col-sm-6 col-md-4 col-lg-3 item my-3" id="gallery">
            <a href="#" data-lightbox="photos">
              <img className="img-fluid" alt="" src={img} />
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 item my-3" id="gallery">
            <a href="#" data-lightbox="photos">
              <img className="img-fluid" src={img2} />
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 item my-3" id="gallery">
            <a href="#" data-lightbox="photos">
              <img className="img-fluid" src={img3} />
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 item my-3" id="gallery">
            <a href="#" data-lightbox="photos">
              <img className="img-fluid" src={img4} />
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 item my-3" id="gallery">
            <a href="#" data-lightbox="photos">
              <img className="img-fluid" src={img5} />
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 item my-3" id="gallery">
            <a href="#" data-lightbox="photos">
              <img className="img-fluid" src={img6} />
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 item my-3" id="gallery">
            <a href="#" data-lightbox="photos">
              <img className="img-fluid" src={img7} />
            </a>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 item my-3" id="gallery">
            <a href="#" data-lightbox="photos">
              <img className="img-fluid" src={img8} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
