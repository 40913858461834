import React from 'react';
import {BrowserRouter as Router,Switch,Route,Redirect} from "react-router-dom";
import './App.css';
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Aboutus from "./components/Aboutus";
import Ourproducts from "./components/Ourproducts";
import Newsandevents from "./components/Newsandevents";
import Gallery from "./components/Gallery";
import Contactus from "./components/Contactus"
import Ebroucher from "./components/Ebroucher";
import Footer from "./components/Footer";
import Membrane from "./components/Membrane";
import Tape from "./components/Tape"
import Pump from "./components/Pump"



function App() {
  return (
    <div>
      <Router>
        <Navbar/>
        <Switch> 
        <Redirect exact from="/" to="/home"/>
          <Route path="/Home" component={Home}/>
          <Route path="/Aboutus" component={Aboutus}/>
          <Route path="/Ourproducts" component={Ourproducts}/>
          <Route path="/Pump" component={Pump}/>
          <Route path="/Membrane" component={Membrane}/>
          <Route path="/Tape" component={Tape}/>
          <Route path="/Newsandevents" component={Newsandevents}/>
          <Route path="/Gallery" component={Gallery}/>
          <Route path="/Contactus" component={Contactus}/>
          <Route path="/Ebroucher" component={Ebroucher}/>
          <Route path="/Footer" component={Footer}/>
        </Switch>
        <Footer/>
      </Router>
    </div>
  );
}
export default App;




// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   BrowserRouter,
  
  
// } from "react-router-dom";


// function App() {
//   return (
//     <>
//     <BrowserRouter>
//   <Router>
//         <Navbar />
//         <div className="content">
//         <Switch>
//           <Route exact path="/home">
//            { <Home />}
//           </Route>
//           <Route  path="/aboutus">
//             {<Aboutus />}
//           </Route>
//           <Route exact path="/ourproducts">
//             <Ourproducts />
//           </Route>
//           <Route exact path="/newsandevents">
//             <Newsandevents />
//           </Route>
//           <Route exact path="/gallery">
//             <Gallery/>
//           </Route>
//           <Route exact path="/contactus">
//             <Contactus/>
//           </Route>
//           <Route exact path="/Ebroucher">
//             <Ebroucher/>
//           </Route>
//           <Route exact path="/membrane">
//             <Membrane/>
//           </Route>
//           <Route exact path="/tape">
//             <Tape/>
//           </Route>
//           <Route exact path="/pump">
//             <Pump/>
//           </Route>
//         </Switch>
//         </div>
//         </Router>
//         <Footer />
//         </BrowserRouter>
        

       
       
        
//     </>
//   );
// }
// export default App;
