import React from "react";
// import Download from "./images/Download.jpg";
import BROCHURE from "./images/BROCHURE.pdf";
import pdfimage from "./images/pdfimage.png";
import productvideo from "./images/productvideo.mp4";
import ebroucher from "./images/ebroucher.jpg";
import "./Style.css";

export default function Ebroucher() {
  return (
    <div className="body">
      <div style={{ overflowX: "hidden" }}>
        <img
          src={ebroucher}
          className="img-fluid"
          alt="Responsive imag"
          style={{ width: "100%" }}
        />
        <div className="embed-responsive embed-responsive-21by9 container  my-5 ">
          <video
            className="embed-responsive-item container"
            src={productvideo}
            width="100px"
            controls="play"
          ></video>
        </div>

        <table className="container my-5" style={{ height: "100px" }}>
          <tr>
            <th
              style={{ backgroundColor: "", fontSize: "20px" }}
              className=" px-4  "
            >
              {" "}
              Brands
            </th>
            <th
              style={{ backgroundColor: "", fontSize: "20px" }}
              className="px-4  "
            >
              Brochure{" "}
            </th>
          </tr>
          <tr>
            <td className="px-4 ">CATALOGUE</td>
            <td className="px-4 ">
              <a href={BROCHURE} target="_blan" rel="nopper">
                <img src={pdfimage} alt="" style={{ height: "100px" }} />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}
