import React from "react";
import teamwork from "./images/teamwork.png";
import diamond from "./images/diamond.jpg";
import havpumps from "./images/havpumps.jpg";
import pump from "./images/pump.jpg";
import director from "./images/director.png";
import mission from "./images/mission.png";
import vision from "./images/vision.png";
import values from "./images/values.png";
import flagvector from "./images/flagvector.png";
import "./Style.css";
import { useState } from "react";
import banner from "./images/main-banner.png";
import Testimonial from "./Testimonial";

export default function Home() {
  const [full_name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const postPutEvent = async (event) => {
    event.preventDefault();
    if(!full_name||!email||!phone||!message){
      alert("Please enter All Fields")
      return
    }
    const data = {
      full_name,
      email,
      phone,
      message,
    };
    const url = "https://api.vhro.in/api/product/Contact";
    console.log(data);
    try {
      let res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      res = await res.json();
      alert("mail sent");
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="body">
      <div style={{ overflowX: "hidden " }}>
        <section className=" wrapper image-wrapper bg-full bg-image bg-overlay bg-overlay-light-600    pagli ">
          <div className=" ">
            <div className="row gx-0 gy-10 align-items-center text-center text-lg-center  ">
              <div className="col-lg-6 col-xl-6 ">
                <div className="   " style={{ paddingLeft: "250px" }}>
                  <img className="" src={flagvector} alt="" />
                </div>
                <h1 className=" india display-1  mb-4  ">
                  India's Largest
                  <br />
                  Manufacturer of <br />
                  <p className="bluee" style={{ fontWeight: "bold" }}>
                    {" "}
                    Booster Pump
                  </p>
                </h1>
              </div>

              <div className="  col-lg-6   tali ms-auto mb-n20  mb-xxl-n22  ">
                <div className="demo">
                  <div className="hover-point"></div>
                  <div className="hover-point"></div>
                  <div className="hover-point"></div>
                  <div className="hover-point"></div>
                  <div className="hover-point"></div>
                  <div className="hover-point"></div>
                  <div className="hover-point"></div>
                  <div className="hover-point"></div>
                  <div className="hover-point"></div>
                  <div className="hover-point"></div>

                  <div className="box-ponks">
                    <img className="container  " src={banner} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="row">
          <div className="col-lg-6 row text-center">
            <img
              className=" w-100 container my-0 col-lg-4 wraper "
              src={teamwork}
              alt=""
              style={{ scale: "70%" }}
            />
          </div>
          <div
            className="col-lg-6"
            style={{ textAlign: "justify", textJustify: "inter-word" }}
          >
            <div className="p-5 mt-4 ">
              <h1
                style={{ color: "#2a8dfc", fontWeight: "bold" }}
                className=" hover  "
              >
                WHO WE ARE
              </h1>

              <p className="lead">
                The “Hiren Water Tech Pvt. Ltd.” is one of the leading Booster
                pumps manufacturer’s with most dynamic & productive brands in
                the water industry. Since 2017- the inception year, so today we
                have expanded our reach to national & international arenas.
                Finest skills clubbed with state-of-the-art technology have
                helped us carve a niche stamped by authorities as we are ISO
                9001:2015 Certified.
              </p>
              <a href="aboutus" className="btn btn-outline-dark hoverme">
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="container" style={{ paddingTop: "20px" }}>
          <div className="row text-center ">
            <div className="col-lg-4  ">
              <img src={mission} alt="" height={135} />
              <h3 className="my-3" style={{ fontWeight: "bolder" }}>
                Mission
              </h3>
              <p
                className="mt-3"
                style={{ textAlign: "justify", textJustify: "inter-word" }}
              >
                Collaboratively design and implement solutions, together with
                our clients, that truly makes a difference. We are committed to
                growing with our customers by delivering greater business value
                through the alignment of technology, business processes,
                organisational structure and strategy.
              </p>
            </div>
            <div className="col-lg-4">
              <img src={vision} alt="" height={135} />
              <h3 className="my-3" style={{ fontWeight: "bolder" }}>
                Vision
              </h3>
              <p
                className="mt-3"
                style={{ textAlign: "justify", textJustify: "inter-word" }}
              >
                Our vision provides the foundation and structure for our mission
                and directs every portion of our business by pointing directions
                to move forward, which will ultimately give our company steady
                and ever-increasing growth. As a group, we would like to be a
                leading manufacturer & exporter in the water industry in India.
              </p>
            </div>
            <div className="col-lg-4">
              <img src={values} alt="" height={135} />
              <h3 className="my-3" style={{ fontWeight: "bolder" }}>
                Value
              </h3>
              <p
                className="mt-3"
                style={{ textAlign: "justify", textJustify: "inter-word" }}
              >
                We regard quality as our most successful attribute. It is the
                key to our success, today and tomorrow. Quality is a hallmark of
                our products. We will ensure growth commitment by offering a
                quality product and service that exceeds the customer’s
                expectations at a profitable and reasonable cost.
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "white",
          }}
        >
          <div
            className="mail"
            style={{
              textAlign: "center",
              paddingTop: "35px",
              paddingBottom: "10px",
            }}
          >
            <h1
              style={{ color: "#2a8dfc", fontWeight: "bold" }}
              className="hovert"
            >
              Our Products
            </h1>
          </div>
          <div className="container my-5">
            <div className="row text-center">
              <div className="col-lg-4 wraper zoom ">
                <img className="blur  " src={pump} alt="" height={220} />

                <div style={{ fontWeight: "bold" }} className="cont fade ">
                  Legender Booster Pump
                </div>
                <a
                  href="Pump"
                  className="btn btn-outline-dark mb-3 hoverme my-3"
                >
                  VIEW PRODUCTS
                </a>
              </div>
              <div className="col-lg-4 wraper zoom">
                <img className="blur  " src={diamond} alt="" height={220} />
                <div style={{ fontWeight: "bold" }} className="cont fade ">
                  Diamond Booster Pump
                </div>
                <a
                  href="Membrane"
                  className="btn btn-outline-dark mb-3 hoverme my-3"
                >
                  VIEW PRODUCTS
                </a>
              </div>
              <div className="col-lg-4 wraper zoom">
                <img className="blur  " src={havpumps} alt="" height={220} />
                <div style={{ fontWeight: "bold" }} className="cont fade ">
                  HAV Booster Pump
                </div>
                <a
                  href="Tape"
                  className="btn btn-outline-dark mb-3 hoverme my-3"
                >
                  VIEW PRODUCTS
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row paglie" style={{ backgroundColor: "" }}>
          <div className="col-lg-7">
            <div
              className="p-5 mt-4"
              style={{
                textAlign: "justify",
                textJustify: "inter-word",
                paddingBottom: "",
              }}
            >
              <h2
                style={{
                  color: "#2a8dfc",
                  fontWeight: "bold",
                }}
                className="display-4 hovers"
              >
                Our Chairman
              </h2>
              <p className="py-3 et-pb-text-inner " style={{}}>
                Progress for me has never been a dream. It has always been a
                distant reality and once one milestone is achieved. There is
                always another one waiting to be crossed. It is with this belief
                that I started Hiren Water Tech Private Limited with the brand
                name V.H.RO. In 2017. A company that was in the business of Ro
                water purifier parts manufacturing such as a RO Booster Pump.
                But like every entrepreneur who sticks to their dream, I always
                wanted to push my company to more prosperous milestones. More
                than being a global player, I always perceived my company as a
                unit that could make a different with its quality, service, and
                competitive price products. Taking a path less traveled is a
                highly sensitive business that requires a high amount of
                technical know-how, expertise, and specialized knowledge.
                <br />{" "}
              </p>
              <h1
                className="pt-3"
                style={{ lineHeight: "30px", fontWeight: "bold" }}
              >
                Mr. Hiren Vekariya <br />{" "}
                <span style={{ fontSize: "20px" }}>
                  (Chairman - V.H.RO Group){" "}
                </span>{" "}
              </h1>
            </div>
          </div>
          <div className=" talie container col-lg-4 ">
            <img
              src={director}
              alt=""
              width={408}
              height={612}
              style={{ marginBottom: "10px" }}
            />
          </div>
        </div>

        <Testimonial />
        <br />

        <div
          className="mail"
          style={{
            backgroundColor: "",
            textAlign: "center",
            paddingTop: "5px",
            paddingBottom: "10px",
          }}
        >
          <h2
            style={{ color: "#2a8dfc", fontWeight: "bold" }}
            className="hoverk"
          >
            Get In Touch
          </h2>
          <div className="container mt-5 mb-5">
            <form>
              <div className="mb-3">
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={full_name}
                  type="Name"
                  placeholder="Full name*"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
                <div id="emailHelp" className="form-text"></div>
              </div>
              <div className="mb-3">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="Name"
                  placeholder="Email*"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
                <div id="emailHelp" className="form-text"></div>
              </div>

              <div className="mb-3">
                <input
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  type="tel"
                  placeholder="Phone*"
                  className="form-control"
                  id="exampleInputPassword1"
                />
              </div>
              <div className="mb-3">
                <textarea
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  type="text area"
                  placeholder="Message*"
                  className="form-control"
                  rows="3"
                  id="exampleInputPassword1"
                />
              </div>
              <button
                onClick={postPutEvent}
                name="submit"
                type="submit"
                id="contact-submit"
                data-submit="...Sending"
                className="btn btn-outline-dark hoverme mt-4"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
