import React from "react";
import global1 from "./images/global1.jpg";
import img11 from "./images/img11.jpg";
import img22 from "./images/img22.jpg";
import img33 from "./images/img33.jpg";
import img44 from "./images/img44.jpg";
import img55 from "./images/img55.jpg";
import img66 from "./images/img66.jpg";
import img77 from "./images/img77.jpg";
import img88 from "./images/img88.jpg";
import img99 from "./images/img99.jpg";
import img100 from "./images/img100.jpg";
import img101 from "./images/img101.jpg";
import img102 from "./images/img102.jpg";
import img103 from "./images/img103.jpg";
// import events from "./images/events.png";
import "./Style.css";

export default function Newsandevents() {
  return (
    <div className="body">
      <img
        src={global1}
        className="img-fluid"
        alt="Responsive imag"
        style={{ width: "100%" }}
      />

      <div className="container my-5">
        <div className="row text-center ">
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img11} alt="" h />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : Chennai 2019
            </div>
          </div>
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img22} alt="" h />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : Pune 2019
            </div>
          </div>
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img33} alt="" h />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : Pune 2019
            </div>
          </div>
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img44} alt="" h />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : Pune 2019
            </div>
          </div>
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img55} alt="" h />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : Delhi 2019
            </div>
          </div>
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img66} alt="" h />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : Delhi 2019
            </div>
          </div>
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img77} alt="" h />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : Delhi 2019
            </div>
          </div>
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img88} alt="" h />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : VH LEGENDER
            </div>
          </div>
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img99} alt="" h />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : VH LEGENDER
            </div>
          </div>
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img100} alt="" />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : VH LEGENDER
            </div>
          </div>
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img101} alt="" />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : VH LEGENDER
            </div>
          </div>
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img102} alt="" />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : VH LEGENDER
            </div>
          </div>
          <div className=" col-lg-4 draper soom py-4">
            <img className=" container  " src={img103} alt="" />
            <div style={{ fontWeight: "bold" }} className="sont sade ">
              WATER INDIA EXPO <br />
              Events On : VH LEGENDER
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
