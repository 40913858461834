
import React from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import havimage from "./images/havimage.jpg";
import havperameter from "./images/havperameter.png";
import pfeatu from "./images/pfeatu.png";

function Tape() {
  return (
    <div className='body'>
    <div className="container">
        <div className="">
          <h1
            style={{ fontWeight: "bold", color: "#2a8dfc",marginTop:"60px" }}
            className="text-center "
          >
            HAV-100-GPD PUMP
          </h1>
        </div>
        <div className="row  " >
          <div className="col-lg-6 container">
            <img
              className="  container paliwal "
              src={havimage}
              alt=''
              style={{ marginTop: "15%" }}
            />
          </div>

          <div className="col-lg-4 container my-5">
            <div className="  et-pb-text-inner container">
              <Carousel className="main-slide">
                <div>
                  <img src={havperameter} alt=''/>
                </div>
                <div>
                  <img src={pfeatu} alt=''/>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Tape
