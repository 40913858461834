import React from "react";
import vlogo from "./images/vlogo.png";
import "./Style.css";

export default function Footer() {
  return (
    <>
      <footer className="text-center text-lg-start text-dark body " style={{}}>
        <section className="">
          <div className="container text-center text-md-start mt-5 p-4">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auhref mb-4 ">
                <img
                  src={vlogo}
                  alt=""
                  className="text-uppercase fw-bold mb-4 mt-0 d-inline-block mx-auto "
                  height={50}
                ></img>
              </div>

              <div
                className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4"
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Quick Links</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <a
                    style={{ textDecoration: "none" }}
                    href="Home"
                    className="text-dark"
                  >
                    Home
                  </a>
                </p>
                <p>
                  <a
                    style={{ textDecoration: "none" }}
                    href="Aboutus"
                    className="text-dark"
                  >
                    About Us
                  </a>
                </p>
                <p>
                  <a
                    style={{ textDecoration: "none" }}
                    href="Ourproducts"
                    className="text-dark"
                  >
                    Our Products
                  </a>
                </p>
                <p>
                  <a
                    style={{ textDecoration: "none" }}
                    href="Newsandevents"
                    className="text-dark"
                  >
                    Events
                  </a>
                </p>
                <p>
                  <a
                    style={{ textDecoration: "none" }}
                    href="Gallery"
                    className="text-dark"
                  >
                    Gallery
                  </a>
                </p>
                <p>
                  <a
                    style={{ textDecoration: "none" }}
                    href="ContactUs"
                    className="text-dark"
                  >
                    Contact Us
                  </a>
                </p>
              </div>

              <div
                className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4"
                style={{ textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />

                <p style={{ display: "flex", gap: "2px" }}>
                  <i className="fas fa-home mx-1 mt-2"></i>
                  <a
                    style={{ textDecoration: "none", color: "black" }}
                    href="https://goo.gl/maps/Hc56F3q4tHJbSiuw7"
                    target="_blan"
                    rel="nopper"
                  >
                    {" "}
                    Devla gate, Vajalpara, Savarkundla – Gujarat 364515, INDIA{" "}
                  </a>
                </p>
                <p>
                  <i className=" fas fa-phone mx-1 "> </i>{" "}
                  <a
                    style={{ textDecoration: "none", color: "black" }}
                    href="tel:918141318161"
                  >
                    {" "}
                    (+91) 81413 18161{" "}
                  </a>
                </p>

                <p>
                  <i className="fas fa-phone mx-1 "></i>{" "}
                  <a
                    style={{ textDecoration: "none", color: "black" }}
                    href="tel:7878486848"
                  >
                    {" "}
                    (+91) 78784 86848{" "}
                  </a>
                </p>
                <p>
                  <i className="fas fa-envelope mx-1 "></i>{" "}
                  <a
                    style={{ textDecoration: "none", color: "black" }}
                    href="mailto:info@vhro.in"
                    target="_blan"
                    rel="nopper"
                  >
                    {" "}
                    info@vhro.in{" "}
                  </a>
                </p>
              </div>
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <iframe
                  style={{}}
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7432.524480870462!2d71.304403!3d21.340149!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2ba40adc85bfee26!2sVH%20RO!5e0!3m2!1sen!2sin!4v1676526377643!5m2!1sen!2sin"
                  width="300"
                  height="250"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        <div className="footer-flex">
          <div className="text-center p-3 " style={{ fontSize: "14px" }}>
            Copyright © 2023, Hiren Water Tech PVT LTD , All Rights Reserved.
          </div>
          <div className="text-center p-3" style={{ fontSize: "14px" }}>
            Design And Developed By : Flourish Creations Pvt. Ltd.
          </div>
        </div>
      </footer>
    </>
  );
}
